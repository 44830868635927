import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f1b434', // FUTF-gul - Använd lite överallt
        secondary: '#23afa1', // Turkos - ???????????????????
        success: '#51763c', // Grön
        // Används inte än, men kan användas av OK-rutor exempelvis
        accent: '#000000', // Becksvart
        // Används exempelvis av markerad kategori i navigeringslistan
        error: '#671019', // Djupt röd
        // Används inte än, men kan användas av felmeddelanden
        background: '#ffffff', // Vit
        eventcolor: '#26c6da', // Ljusturkos
        newscolor: '#ff0000' // Röd
      },
      dark: {
        primary: '#f1b434', // FUTF-gul
        secondary: '#424242', // Grey draken-3
        success: '#4CAF50', // Green
        accent: 'ffffff', // Grey
        error: '#FF5252', // Pink/Red
        background: '#212121', // Grey darken-4
        eventcolor: '#1a739c', // Ljusblå
        newscolor: '#ff0000' // Röd
      }
    },
    dark: false,
    options: { customProperties: true }
  }
})
