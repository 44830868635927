<template>
  <v-container>
    <h2 style="text-align: center">
      <textloader-component textKey="Infocards" />
    </h2>

    <v-sheet class="my-2" outlined color="primary" rounded>
      <v-card class="my-1 mx-1" elevation="0">

        <v-card-title>
          <textloader-component textKey="CaFéRubrik"/>
        </v-card-title>
        <v-card-text>
          <textloader-component textKey="CaFéBrödtext"/>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="show1 = !show1">
            <textloader-component textKey="LäsMer"/>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show1">
            <v-card-text>
              <textloader-component textKey="CaFéMertext"/>
            </v-card-text>
          </div>
        </v-expand-transition>

      </v-card>
    </v-sheet>

    <v-sheet class="my-2" outlined color="primary" rounded>
      <v-card class="my-1 mx-1" elevation="0">
        <v-card-title >

          <textloader-component textKey="OrbiRubrik"/>
        </v-card-title>
        <v-card-text >
          <textloader-component textKey="OrbiBrödtext"/>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="show2 = !show2">
            <textloader-component textKey="LäsMer"/>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show2">
            <v-card-text>
              <textloader-component textKey="OrbiMertext"/>
            </v-card-text>
          </div>
        </v-expand-transition>

      </v-card>
    </v-sheet>

    <v-sheet class="my-2" outlined color="primary" rounded>
      <v-card class="my-1 mx-1" elevation="0">
        <v-card-title>
          <textloader-component textKey="SocialaMedierRubrik"/>
        </v-card-title>
        <v-card-text >
          <textloader-component textKey="SocialaMedierBrödtext"/>
        </v-card-text>
        <a
          target="_blank"
          v-bind:href="social.url"
          v-for="social in socials"
          v-bind:key="social.url"
        >
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px">{{ social.icon }}</v-icon>
          </v-btn>
        </a>
      </v-card>
    </v-sheet>
  </v-container>
</template>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'

export default {
  data: function () {
    return {
      show1: false,
      show2: false,
      socials: [
        {
          icon: 'mdi-facebook', url: 'https://www.facebook.com/futf.se/'
        },
        {
          icon: 'mdi-linkedin', url: 'https://www.linkedin.com/company/f%C3%B6reningen-uppsala-tekniska-fysiker/about/'
        },
        {
          icon: 'mdi-instagram', url: 'https://www.instagram.com/uppsala_tekniska_fysiker/'
        }
      ]
    }
  },
  components: {
    TextloaderComponent
  }
}
</script>
