import Vue from 'vue'
import VueRouter from 'vue-router'
import navdata from '@/assets/navdata.json'
import { store } from '../store/store'

Vue.use(VueRouter)

// Get page info
const routes = []

// Import and add all "standard" pages
for (const page of navdata.pageinfo) {
  routes.push({
    path: page.path,
    component: () => import('@/views/' + page.componentPath)
  })
}

// Add misc. pages
routes.push(
  {
    path: '/schema/:classID',
    props: true,
    component: () => import('@/views/aktuellt/Schema')
  },
  {
    path: '/allahandelser/:eventID',
    props: true,
    component: () => import('@/views/aktuellt/AllaHandelser')
  },
  {
    path: '/login',
    component: () => import('@/views/admin/Login')
  },
  {
    path: '/logout',
    component: () => import('@/views/admin/Logout')
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/Admin')
  },
  {
    path: '/addEvent/:eventID',
    props: route => ({ eventID: Number(route.params.eventID) }),
    component: () => import('@/views/AddEvent')
  },
  {
    path: '/addEvent',
    component: () => import('@/views/AddEvent')
  },
  {
    path: '/adminEventList',
    component: () => import('@/views/admin/AdminEventList')
  },
  {
    path: '/manageBookings',
    props: true,
    component: () => import('@/views/admin/ManageBooking')
  },
  {
    path: '/formsAnswers',
    component: () => import('@/views/admin/FormsAnswers')
  },
  {
    path: '/landingpage',
    component: () => import('@/views/LandingPage')
  },
  {
    path: '/settings',
    component: () => import('@/views/Settings')
  },
  {
    path: '/furka',
    component: () => import('@/games/Furka')
  },
  {
    path: '/adminQuestionBox',
    component: () => import('@/views/admin/QuestionBox')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '/tupptype_leaderboard',
    name: 'leaderboard',
    component: () => import('@/views/games/TuppType/LeaderboardView.vue')
  }
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// These are the routes requiring authentication
// IMPORTANT: add route in LOWERCASE!

const routesThatRequireAuth = ['/addevent', '/admin', '/admineventlist', '/adminquestionbox', '/formsanswers']

router.beforeEach(async (to, from, next) => {
  if (routesThatRequireAuth.includes(to.path.toLowerCase())) {
    // If the destination requires authentication
    const resp = await store.dispatch('isAuth')
    if (resp.auth) {
      // If we are authenticated, go ahead to the page
      next()
    } else {
      // Redirect to login
      // Pass `from` as a query parameter to allow the
      // login page to redirect the user to the page they tried to access
      next({ path: '/login', query: { redirect: to.path } })
    }
  } else {
    next()
  }
})

export default router
