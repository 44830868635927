/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  const serviceWorkerPath = `${process.env.BASE_URL}service-worker.js`

  register(serviceWorkerPath, {
    ready () {
      console.log(
        'App is being served from cache by a service worker (force cache).\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')

      // Send a message to the waiting service worker to skip waiting and activate immediately
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ action: 'skipWaiting' })
      }
    },
    updated (registration) {
      console.log('New content is available; changes will take effect automatically.')

      // Send a message to the waiting service worker to skip waiting and activate immediately
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ action: 'skipWaiting' })
      }

      window.location.reload(true)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
