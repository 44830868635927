// This is the Javascript library behind TextloaderComponent

// The texts in different languages are stored in assets/text.json and loaded
// using the key and language specified.
// If a text item doesn't exists, a console.log message will be printed with the key
// and the text 'Something went wrong loading the text :('

const data = require('./assets/text.json')

export default function textManager (lang, key) {
  if (key in data) {
    if (lang in data[key]) {
      return data[key][lang]
    } else if (lang === 'rov') {
      const text = data[key].swe
      let transformedText = ''
      for (const c of text) {
        transformedText += c
        if (['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'z'].includes(c.toLowerCase())) {
          transformedText += 'o' + c.toLowerCase()
        }
      }
      return transformedText
    } else if (lang === 'eko') {
      const text = data[key].swe
      let transformedText = ''
      for (const c of text) {
        if (['.', '!', '?', ',', '&', '-', '+', ':', ';', 'å', 'ä', 'ö'].includes(c)) {
          transformedText += ['🐿️', '🌰', '🌲'][Math.floor(Math.random() * 3)]
        } else {
          transformedText += c
        }
      }
      return transformedText
    } else {
      console.error('Language ' + lang + ' was not found for textkey ' + key)
      return 'Something went wrong loading the text :('
    }
  } else {
    console.error('Textkey ' + key + ' was not found!')
    return 'Something went wrong loading the text :('
  }
}
