<template>
  <!--
  This component loads text from the underlying Texthanterare.js
  which uses assets/text.json for storing the texts in different languages.
  Usage:
    <textloader-component :textKey="key"/>
  where "key" is the key used in text.json.
  The language will be fetched from the Vuex store.
   -->
  <div v-html="loadText(lang, textKey)">
  </div>
</template>

<script>
import textManager from '@/Texthanterare.js'
export default {
  props: {
    textKey: String
  },
  computed: {
    lang: function () {
      return this.$store.getters.lang
    }
  },
  methods: {
    loadText: function (lang, key) {
      return textManager(lang, key)
    }
  }
}
</script>
