<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      :touchless="this.$vuetify.breakpoint.lgAndUp"
    >
      <navigation-list />
      <v-row justify="center" v-if="isApp() || true"
        ><v-btn color="primary" to="/settings">{{
          currentLang === "eng" ? "Settings" : "Inställningar"
        }}</v-btn></v-row
      >
      <v-row justify="center" v-if="this.$vuetify.breakpoint.xsOnly">
        <v-col cols="5" v-show="currentLang != 'eng'">
          <div @click="changeLang('eng')">
            <v-img :src="langIcons['eng']"></v-img>
          </div>
        </v-col>
        <v-col cols="5" v-show="currentLang != 'swe'">
          <div @click="changeLang('swe')">
            <v-img :src="langIcons['swe']"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <!-- Just testing -->

    <v-navigation-drawer
      app
      clipped
      right
      touchless
      v-if="$router.history.current['path'] == '/'"
    >
      <sponsorlist />
      <infocards />
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      clipped-right
      app
      class="px-0 .d-flex"
      color="background"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="this.$vuetify.breakpoint.mdAndDown"
      />
      <h1 v-if="!onLine"> Offline! </h1>
      <router-link
        style="height: 40px"
        to="/"
        tag="img"
        :src="
          require(isDark
            ? '@/assets/futflogga_dark.png'
            : '@/assets/futflogga.png')
        "
      />

      <v-spacer></v-spacer>

      <router-link
        v-if="isAuth"
        style="height: 40px"
        to="/admin"
        tag="img"
        :src="
          require('@/assets/home-icon.png'
            )
        "
      />

      <v-menu v-if="this.$vuetify.breakpoint.smAndUp">
        <template v-slot:activator="{ on }">
          <v-btn text height="100%" class="pa-1" v-on="on">
            <v-avatar>
              <v-img
                :src="langIcons[currentLang]"
                :aspect-ratio="3 / 2"
                height="100%"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-1">
          <v-list-item @click="changeLang('swe')" class="pa-1">
            <v-avatar>
              <v-img :src="langIcons['swe']" :aspect-ratio="3 / 2" />
            </v-avatar>
          </v-list-item>
          <v-list-item @click="changeLang('eng')" class="pa-1">
            <v-avatar>
              <v-img :src="langIcons['eng']" :aspect-ratio="3 / 2" />
            </v-avatar>
          </v-list-item>
          <v-list-item @click="changeLang('rov')" class="pa-1">
            <v-avatar>
              <v-img :src="langIcons['rov']" :aspect-ratio="3 / 2" />
            </v-avatar>
          </v-list-item>
          <v-list-item @click="changeLang('eko')" class="pa-1">
            <v-avatar>
              <v-img :src="langIcons['eko']" :aspect-ratio="3 / 2" />
            </v-avatar>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view v-if="onLine"/>
      <Furka v-if="!onLine"/>
    </v-main>

    <v-footer app absolute inset color="primary">
      <v-col class="text-center pb-0 pt-1" cols="12">
        &copy; Made by IT-gruppen 2021
      </v-col>
      <v-col class="text-center pb-0 pt-1" cols="12">
        <a
          target="_blank"
          v-bind:href="social.url"
          v-for="social in socials"
          v-bind:key="social.url"
        >
          <v-btn class="mx-4 white--text" icon>
            <v-icon size="24px">{{ social.icon }}</v-icon>
          </v-btn>
        </a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<style lang="sass">
@import '@/sass/variables.sass'
h1, h2, h3, h4
  font-family: $heading-font-family
  ::-webkit-scrollbar
    width: 10px
  ::-webkit-scrollbar-track
    background: #f1f1f1
  ::-webkit-scrollbar-thumb
    background: #bbb
  ::-webkit-scrollbar-thumb:hover
    background: #aaa
</style>

<script>
import NavigationList from '@/components/NavigationDrawerList'
import sponsorlist from '@/components/SponsorComponent.vue'
import infocards from '@/components/InfoCardComponent.vue'
import Furka from '@/games/Furka.vue'

export default {
  name: 'App',
  data: function () {
    return {
      onLine: navigator.onLine,
      sponsors: [
        'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        'https://cdn.vuetifyjs.com/images/carousel/sky.jpg'
      ],
      drawer: null,
      langIcons: {
        swe: require('@/assets/svenskflagga.svg'),
        eng: require('@/assets/ukflag.svg'),
        rov: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Richard_Worley_Flag.svg',
        eko: 'https://images.emojiterra.com/google/noto-emoji/unicode-13.1/share/1f43f.jpg'
      },
      socials: [
        {
          icon: 'mdi-facebook', url: 'https://www.facebook.com/futf.se/'
        },
        {
          icon: 'mdi-linkedin', url: 'https://www.linkedin.com/company/f%C3%B6reningen-uppsala-tekniska-fysiker/about/'
        },
        {
          icon: 'mdi-instagram', url: 'https://www.instagram.com/uppsala_tekniska_fysiker/'
        }
      ]
    }
  },
  computed: {
    isDark: function () { return this.$vuetify.theme.dark },
    currentLang: function () {
      return this.$store.getters.lang
    },
    isAuth: function () {
      return this.$store.state.user.auth
    }
  },
  components: { NavigationList, sponsorlist, infocards, Furka },
  methods: {
    isApp: function () {
      if (navigator.standalone || matchMedia('(display-mode: standalone)').matches) {
        return true
      } else {
        return false
      }
    },
    changeLang: function (lang) {
      this.$store.commit('changeLang', lang)
    }
  }
}
</script>
