import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

const state = {
  eventLoading: false
}

const getters = {
  eventLoading: (state) => state.eventLoading
}

const actions = {
  async createEvent ({ commit }) {
    try {
      // Keep track on the fact that we are waiting for the request
      commit('setLoading', true)

      commit('setLoading', false)
      // From ./notification.js
      commit('successGlobal', 'Inloggad!')
    } catch (error) {
      commit('setLoading', false)
      // From ./notification.js
      commit('errorGlobal', error.response.data.message)
    }
  },
  async sendEvent ({ commit }, fileData) {
    try {
      if (fileData.get('filesize' > 10)) {
        commit('errorGlobal', 'Filen är för stor (max 10 MB)')
      } else {
        commit('setEventLoading', true)
        await axios.post('/api/events/create', fileData, getAuthHeader())
        commit('setEventLoading', false)
        // From ./notification.js
        commit('successGlobal', 'Event uppladdat!')
      }
    } catch (error) {
      commit('setEventLoading', false)

      // Make the error message from MIME more detailed
      if (error.response.data.message === 'File too large') {
        error.response.data.message = 'Filen är för stor (max 85 MB)'
      }
      commit('errorGlobal', error.response.data.message)
      console.log(error.response.data)
    }
  },
  async editEvent ({ commit }, fileData) {
    try {
      if (fileData.get('filesize' > 10)) {
        commit('errorGlobal', 'Filen är för stor (max 10 MB)')
      } else {
        commit('setEventLoading', true)
        await axios.put('/api/events/edit', fileData, getAuthHeader())
        commit('setEventLoading', false)
        // From ./notification.js
        commit('successGlobal', 'Event uppdaterat!')
      }
    } catch (error) {
      commit('setEventLoading', false)

      // Make the error message from MIME more detailed
      if (error.response.data.message === 'File too large') {
        error.response.data.message = 'Filen är för stor (max 85 MB)'
      }
      commit('errorGlobal', error.response.data.message)
      console.log(error.response.data)
    }
  }
}

const mutations = {
  setEventLoading: (state, newLoading) => {
    state.eventLoading = newLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
