import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import { store } from '@/store/store'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@mdi/font/css/materialdesignicons.css'
// import 'vue-search-select/dist/VueSearchSelect.css'

Vue.config.productionTip = false

// Proxy the URL
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || 'https://futf.se'
// Cookies!
Vue.use(VueCookies)
// Toasts!
Vue.use(Toast)

new Vue({
  router,
  vuetify,
  render: h => h(App),
  store: store,
  beforeCreate () { this.$store.commit('initialiseStore') }
}).$mount('#app')
