import Vue from 'vue'

export const getTokenCookie = () => Vue.prototype.$cookies.get('x-access-token')
export const setTokenCookie = (token) => Vue.prototype.$cookies.set('x-access-token', token, 60 * 60 * 8)
// export const removeTokenCookie = () => Vue.prototype.$cookies.remove('x-access-token')
export const removeTokenCookie = () => Vue.prototype.$cookies.remove('x-access-token', { path: '/' })
export const getAuthHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`
    }
  }
}
